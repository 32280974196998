import { FC, memo, ReactComponentElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getToken, setToken } from 'reducers/token/function';
import { Provider } from 'route/Context';
import routeConstants from 'route/routeConstant';
import RouteNavigate from 'route/RouteNavigate';
import authenticateService from 'services/autoAuthenticateService';
interface IProps {
    children: ReactComponentElement<any>;
    authorization?: boolean;
    redirect?: string;
    path: string;
}

const RouteElement: FC<IProps> = memo(
    ({ children, authorization, redirect, path }) => {
        const [authorized, setAuthorized] = useState(!authorization);
        const navigate = useNavigate();

        useEffect(() => {
            if (authorization) {
                const token = getToken();

                if (!token) {
                    navigate(routeConstants.LOGIN, { replace: true });
                    return;
                }

                authenticateService.getMe().then(res => {
                    if (res.isSuccess) {
                        setAuthorized(true);
                    } else {
                        setToken('');
                        navigate(routeConstants.LOGIN, { replace: true });
                    }
                });
            }
        }, [authorization, navigate]);

        if (!authorized) {
            return null;
        }
        if (redirect) {
            return <RouteNavigate to={redirect} />;
        }
        return <Provider path={path}>{children}</Provider>;
    }
);
RouteElement.displayName = 'RouteElement';

export default RouteElement;
