import ApiBase from 'modules/apis/apiBase';
import type { IResponseData } from 'modules/apis/Response';


export interface ApiLoginResponse {
    user: ApiUserSchema;
    accessToken: string;
}

export interface ApiUserSchema {
    username: string;
    firstName?: string | null;
    lastName?: string | null;
}

export interface ApiLoginSchema {
    username: string;
    password: string;
}

export interface ApiUpdateMeSchema {
    firstName: string;
    lastName: string;
}

export interface ApiChangePasswordSchema {
    oldPassword: string;
    newPassword: string;
}

class AuthenticateService extends ApiBase {

    login = (jsonData: ApiLoginSchema) => {
        return this.post(`/auth/login`, {
            jsonData,
            useToken: false,
        }) as Promise<IResponseData<ApiLoginResponse>>;
    };

    getMe = () => {
        return this.get(`/auth/detail/me`) as Promise<IResponseData<ApiUserSchema>>;
    };

    logout = () => {
        return this.post(`/auth/logout`) as Promise<IResponseData<boolean>>;
    };

    updateMe = (jsonData: ApiUpdateMeSchema) => {
        return this.put(`/auth/update/me`, {
            jsonData,
        }) as Promise<IResponseData<ApiUserSchema>>;
    };

    changePassword = (jsonData: ApiChangePasswordSchema) => {
        return this.put(`/auth/change-password`, {
            jsonData,
        }) as Promise<IResponseData<boolean>>;
    };

}

const authenticateService = new AuthenticateService();

export default authenticateService;
