const routeConstants = {
    LOGIN: '/login',
    BOOKING: '/booking',
    MAIN: '/',
    ADMIN: '/admin',
    ADMIN_BOOKING: '/admin/booking',
    ADMIN_PATIENT: '/admin/patient',
} as const;

export default routeConstants;
