import Loadable from 'react-loadable';
import LoadableLoading from 'route/LoadableLoading';
import routeConstants from 'route/routeConstant';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';

const commonLoadable = (loader: () => Promise<any>) =>
    Loadable({
        loader,
        loading: LoadableLoading,
    });

const routes = [
    {
        name: 'Login',
        path: routeConstants.LOGIN,
        Component: commonLoadable(() => import('views/Login')),
    },
    {
        name: 'Admin / Bookings',
        path: routeConstants.ADMIN,
        authorization: true,
        Component: commonLoadable(() => import('views/Admin')),
        childRoutes: [
            {
                path: routeConstants.ADMIN,
                name: 'Default',
                redirect: routeConstants.ADMIN_BOOKING,
            },
            {
                name: 'Booking',
                path: routeConstants.ADMIN_BOOKING,
                Component: commonLoadable(() => import('views/Admin/Booking')),
            },
            {
                name: 'Patient',
                path: routeConstants.ADMIN_PATIENT,
                Component: commonLoadable(() => import('views/Admin/Patient')),
            },
        ],
    },
    {
        name: 'Booking',
        path: routeConstants.BOOKING,
        Component: commonLoadable(() => import('views/Booking')),
    },
    {
        name: 'MainLayout',
        path: routeConstants.MAIN,
        Component: commonLoadable(() => import('views/Navigator')),
    },
    {
        name: '404',
        path: '*',
        Component: Loadable({
            loading: LoadingScreen,
            loader: () => import('views/Page/404'),
        }),
    },
];

export default routes;
