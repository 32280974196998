import { Button } from 'antd';
import { memo } from 'react';
import routeConstants from 'route/routeConstant';

const E404 = memo(() => {
    return (
        <a href={routeConstants.MAIN} className="error-page__link">
            <Button type="fill" color="primary" size="large">
                Quay lại trang chủ
            </Button>
        </a>
    );
});
E404.displayName = 'E404';

export default E404;
