import Message from 'components/Message';
import { forEach, isArray, isObject } from 'lodash';
import { BodyTypeEnum } from 'modules/apis/config';
import { getResponse, IResponse } from 'modules/apis/Response';
import { getToken } from 'reducers/token/function';
import { toSnakeCase } from 'utils/function';

type Methods = 'GET' | 'POST' | 'PUT' | 'DELETE';

const prepareData = (response: any): any => {
    let result = null as any;
    if (isArray(response)) {
        result = response.map((item: any) => prepareData(item));
    } else if (isObject(response)) {
        result = {};
        forEach(response, (value, key) => {
            if (key === 'file') {
                result[key] = value;
            } else {
                result[toSnakeCase(key)] = prepareData(value);
            }
        });
    } else {
        result = response;
    }
    return result;
};

export interface IApiOptions {
    bodyType?: BodyTypeEnum;
    params?: any;
    method?: Methods;
    body?: any;
}

interface IApiOptionsV2 {
    jsonData?: Object;
    formData?: FormData;
    rawData?: unknown;
    params?: object | URLSearchParams;
    pathParams?: Record<string, string | number>;
    method: Methods;
    silent?: boolean;
    customHandleError?: (err: IResponse) => IResponse;
    abortController?: AbortController;
    useToken?: boolean;
}

export interface FetchOptions {
    method: Methods;
    headers: Record<string, string>;
    body?: any;
    signal?: AbortSignal;
}

class ApiBase {
    private _baseUrl = process.env.REACT_APP_API_URL + '/api';
    defaultParams: Record<string, string> | null = null;

    getUrl = (pathUrl: string) => {
        return this._baseUrl + pathUrl;
    };

    handleError = (error: IResponse, silent: boolean) => {
        if (!silent) {
            Message.sendError(error.message);
        }
        return error;
    };

    _getUrlV2 = (
        pathUrl: string,
        { pathParams = {}, params = {} }: IApiOptionsV2
    ) => {
        let url = this.getUrl(pathUrl);
        Object.keys(pathParams).forEach(key => {
            url = url.replace(`:${key}`, pathParams[key]?.toString());
        });
        const mergedParams =
            params instanceof URLSearchParams
                ? params
                : new URLSearchParams(
                      Object.entries(params).filter(
                          ([_, v]) =>
                              typeof v === 'string' ||
                              typeof v === 'number' ||
                              typeof v === 'boolean'
                      )
                  );
        if (this.defaultParams) {
            Object.entries(this.defaultParams).forEach(([key, value]) => {
                mergedParams.append(key, value);
            });
        }
        const q = Array.from(mergedParams)
            .map(([key, value]) => `${toSnakeCase(key)}=${value}`)
            .join('&');
        if (q.length > 0) {
            url = url + '?' + q;
        }
        return url;
    };

    _callV2 = async (pathUrl: string, rawOptions: IApiOptionsV2) => {
        const {
            customHandleError,
            silent = false,
            rawData,
            jsonData,
            formData,
            abortController,
            useToken = true,
        } = rawOptions;
        const url = this._getUrlV2(pathUrl, rawOptions);
        let fetchOptions: FetchOptions = {
            method: rawOptions.method,
            headers: {},
        };
        const token = getToken();
        if (useToken && token) {
            fetchOptions.headers.Authorization = `Bearer ${token}`;
        }
        if (jsonData) {
            fetchOptions.body = JSON.stringify(prepareData(jsonData));
            fetchOptions.headers = {
                ...fetchOptions.headers,
                'Content-Type': 'application/json',
            };
        } else if (formData) {
            if (formData instanceof FormData) {
                fetchOptions.body = formData;
            } else {
                fetchOptions.body = new FormData();
                Object.keys(formData).forEach(key => {
                    fetchOptions.body.append(toSnakeCase(key), formData[key]);
                });
            }
        } else if (rawData) {
            fetchOptions.body = rawData;
        }
        if (abortController) {
            fetchOptions.signal = abortController.signal;
        }
        try {
            let response = await fetch(url, fetchOptions);
            const contentType = response.headers.get('content-type');
            if (contentType?.includes('application/json')) {
                const jsonData = await response.json();
                let data: IResponse = getResponse(jsonData);
                if (!data.isSuccess && customHandleError) {
                    data = customHandleError(data);
                } else if (!data.isSuccess) {
                    return this.handleError(data, silent);
                }
                return data;
            } else if (contentType === null) {
                const textData = await response.text();
                const statusCode = response.status;
                return getResponse({
                    errorCode: statusCode === 200 ? 0 : statusCode,
                    data: textData,
                });
            } else {
                const dataBlob = await response.blob();

                const rs = getResponse({
                    errorCode: response.status === 200 ? 0 : response.status,
                    data: dataBlob,
                });
                return rs;
            }
        } catch (error: any) {
            const e = error instanceof Error ? error : new Error(error);
            const response = getResponse(null, e);
            return this.handleError(response, silent);
        }
    };

    callV2 = this._callV2;

    get = (url: string, options?: Omit<IApiOptionsV2, 'method'>) => {
        return this.callV2(url, {
            ...options,
            method: 'GET',
        });
    };

    post = (url: string, options?: Omit<IApiOptionsV2, 'method'>) => {
        return this.callV2(url, {
            ...options,
            method: 'POST',
        });
    };

    put = (url: string, options?: Omit<IApiOptionsV2, 'method'>) => {
        return this.callV2(url, {
            ...options,
            method: 'PUT',
        });
    };

    delete = (url: string, options?: Omit<IApiOptionsV2, 'method'>) => {
        return this.callV2(url, {
            ...options,
            method: 'DELETE',
        });
    };
}

export default ApiBase;
