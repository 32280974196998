import commonReducer from 'reducers/common';
import errorReducer from 'reducers/error';
import settingReducer from 'reducers/setting';
import tokenReducer from 'reducers/token';

const reducers = {
    common: commonReducer,
    error: errorReducer,
    setting: settingReducer,
    token: tokenReducer,
};

export default reducers;
