import { memo } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import RouteController from 'route/RouteController';
import store, { persistor } from 'store/Store';
import FontSizeProvider from 'theme/FontSizeProvider';
import FirebaseProvider from 'utils/firebase/FirebaseProvider';
import ErrorContainer from 'views/Error/ErrorContainer';
import LoadingScreen from 'views/LoadingScreen/LoadingScreen';
import './App.scss';

const App = memo(() => {
    return (
        <FirebaseProvider>
            <Provider store={store}>
                <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                    <ErrorContainer>
                        <FontSizeProvider>
                            <RouteController />
                        </FontSizeProvider>
                    </ErrorContainer>
                </PersistGate>
            </Provider>
        </FirebaseProvider>
    );
});
App.displayName = 'App';

export default App;
