// import { getToken, onMessage } from 'firebase/messaging';
import { createContext, memo, useContext, useEffect, useState } from 'react';
import messaging from './config';
import Message from 'components/Message';

const Context = createContext({
    deviceToken: null,
});

export const useFirebaseContext = () => useContext(Context);

const FirebaseProvider = memo(({ children }) => {
    const [deviceToken, setDeviceToken] = useState(null);

    // useEffect(() => {
    //     if (messaging && 'Notification' in window) {
    //         Notification.requestPermission().then(permission => {
    //             if (permission === 'granted') {
    //                 getToken(messaging, {
    //                     vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    //                 })
    //                     .then(deviceToken => {
    //                         if (deviceToken) {
    //                             setDeviceToken(deviceToken);
    //                             // console.log("deviceToken", deviceToken)
    //                         }
    //                     })
    //                     .catch(() => {
    //                         // console.log("An error occurred while retrieving token. ", e);
    //                     });
    //             }
    //         });

    //         onMessage(messaging, payload => {
    //             // console.log("Message received. ", payload);
    //             Message.sendInfo(
    //                 `${payload.notification.title}: ${payload.notification.body}`
    //             );
    //         });
    //     }
    // }, []);
    return (
        <Context.Provider value={{ deviceToken }}>{children}</Context.Provider>
    );
});
FirebaseProvider.displayName = 'FirebaseProvider';

export default FirebaseProvider;
