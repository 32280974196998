import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonState } from 'reducers/common/type';

const initialState: CommonState = {
    isFullScreenDataEntry: false,
};

interface CommonCaseReducer<P>
    extends CaseReducer<CommonState, PayloadAction<P>> {}

const TOGGLE_FULL_SCREEN_DATA_ENTRY: CommonCaseReducer<boolean | undefined> = (
    state,
    { payload }
) => {
    return {
        ...state,
        isFullScreenDataEntry:
            typeof payload === 'boolean'
                ? payload
                : !state.isFullScreenDataEntry,
    };
};

const profileSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        TOGGLE_FULL_SCREEN_DATA_ENTRY,
    },
});

export default profileSlice;
