import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

dayjs.extend(utc);
dayjs.extend(timezone);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker
//         .register('./firebase-messaging-sw.js')
//         .then(function (_registration) {
//             // console.log("Registration successful, scope is:", _registration.scope);
//         })
//         .catch(function (_err) {
//             // console.log("Service worker registration failed, error:", _err);
//         });
// }
